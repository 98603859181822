import { ListMagnifyingGlass } from '@phosphor-icons/react'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

type OrgPositionLinkProps = {
  orgSlug: string
}

export const OrgPositionLink: React.FC<OrgPositionLinkProps> = (props) => {
  const { orgSlug } = props

  const path = `/orgs/${orgSlug}/positions`
  const title = 'Positions'

  return (
    <SideMenu.LinkItem
      active={useIsActive(path)}
      analyticsProps={sideNavAnalyticsProps({
        title,
        href: path,
      })}
      href={path}
      icon={ListMagnifyingGlass}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
